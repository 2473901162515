import React from 'react';
import PropTypes from 'prop-types';
import FooterBigMenu from '../../02_molecules/Footer/FooterBigMenu';
import FooterSocialLinks from '../../02_molecules/Footer/FooterSocialLinks';
import FooterSmallMenu from '../../02_molecules/Footer/FooterSmallMenu';
import FooterLogos from '../../02_molecules/Footer/FooterLogos';
import FooterCopy from '../../02_molecules/Footer/FooterCopy';

import GoogleTranslateWidget from '../../01_atoms/GoogleTranslateWidget';

const Footer = ({ data = {}, nonce }) => (
  <footer className="site-footer">
    <div className="container">
      {(data.bigMenu ||
        (data.socialLinks && !!data.socialLinks.length) ||
        (data.smallMenu && !!data.smallMenu.length) ||
        data.googleTranslateEnabled) && (
        <div className="footer-top-region">
          <div className="big-menu-with-social-links">
            {data.bigMenu && <FooterBigMenu menu={data.bigMenu} />}

            {!!data.socialLinks?.length && <FooterSocialLinks socialLinks={data.socialLinks} />}
          </div>

          <div className="footer-small-menu-wrapper d-lg-flex">
            {data.googleTranslateEnabled && <GoogleTranslateWidget nonce={nonce} />}

            {data.smallMenu && <FooterSmallMenu links={data.smallMenu} />}
          </div>
        </div>
      )}

      <div className="footer-bottom-region">
        {data.logos && !!data.logos.length && <FooterLogos logos={data.logos} />}

        {data.copy && <FooterCopy copy={data.copy} />}
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  data: PropTypes.shape({
    bigMenu: PropTypes.arrayOf(PropTypes.object),
    socialLinks: PropTypes.arrayOf(PropTypes.object),
    smallMenu: PropTypes.arrayOf(PropTypes.object),
    logos: PropTypes.arrayOf(PropTypes.object),
    copy: PropTypes.string,
    googleTranslateEnabled: PropTypes.bool,
  }),
  nonce: PropTypes.string.isRequired,
};

export default Footer;
